import { useState, useEffect } from "react";

import React from "react";
const PictShow = ({ showImg, item }) => {
  const CurrentImg = item.split(".");
  const NewImg = `${CurrentImg[0]}b.${CurrentImg[1]}`;
  const [className, setClassName] = useState("slideNone");
  const [itemToHide, setItemToHide] = useState(true);

  useEffect(() => {
    if (itemToHide && !showImg) {
      setClassName("slideNone");
      console.log("setItemToHide 1: ", itemToHide, showImg);
    } else if (itemToHide && showImg) {
      setClassName("allWindow");
      console.log("setItemToHide 2: ", itemToHide, showImg);
    } else if (!itemToHide && showImg) {
      setClassName("slideNone");
      setItemToHide(true);
      console.log("setItemToHide 3: ", itemToHide, showImg);
    } else {
      setClassName("slideNone");
      setItemToHide(true);
      console.log("setItemToHide 4: ", itemToHide, showImg);
    }
  }, [itemToHide, showImg]);

  return (
    <div onClick={() => setItemToHide(false)} className={className}>
      <div className="container">
        <div className="arrowS">
          <p>&#60;</p>
        </div>
        <div className="">
          <img
            className={showImg ? "slide" : "slideNone"}
            src={NewImg}
            alt="test galery"
          />
        </div>
        <div className="arrowS">
          <p>&#62;</p>
        </div>
      </div>
    </div>
  );
};

export default PictShow;
