export const WallFront = ({ wall }) => {
  return (
    <button className="cube__face cube__face--front">
      <img
        className="imgStyle"
        src={wall.backgroundImg}
        alt="front section item"
      />
    </button>
  );
};
