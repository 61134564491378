import {
  getCubeIndex,
  getLanguageConfig,
  getLinkToFile,
  getWallIndex,
} from "./../../helpers/global.helpers";

export const getBrickContent = (language) => {
  const languageConfig = getLanguageConfig(language);
  return [
    {
      cubeId: 1,
      type: "withAnimation",
      //type: 'goToPage',
      linkToPage: "https://lubocki.com/yuichi/index.html",
      walls: [
        {
          // zdjęcie natury - ma być głos?
          wallId: 1,
          backgroundImg: getLinkToFile("/i1.png"),
          projectDescription:
            languageConfig.bricks[getCubeIndex(1)].walls[getWallIndex(1)]
              .projectDescription,
          projectTechnology:
            languageConfig.bricks[getCubeIndex(1)].walls[getWallIndex(1)]
              .projectTechnology,
          functionToTriger: "tam",
        },
        {
          // projects - right
          wallId: 2,
          backgroundImg: getLinkToFile("/w1.png"),
          projectDescription:
            languageConfig.bricks[getCubeIndex(1)].walls[getWallIndex(1)]
              .projectDescription,
          projectTechnology:
            languageConfig.bricks[getCubeIndex(1)].walls[getWallIndex(1)]
              .projectTechnology,
          functionToTriger: "2222",
        },
        {
          // photos-?
          wallId: 3,
          backgroundImg: getLinkToFile("/m1.png"),
          projectDescription:
            languageConfig.bricks[getCubeIndex(1)].walls[getWallIndex(1)]
              .projectDescription,
          projectTechnology:
            languageConfig.bricks[getCubeIndex(1)].walls[getWallIndex(1)]
              .projectTechnology,
          technicalDescription: "fdfd",
        },
        {
          // front-main
          wallId: 4,
          backgroundImg: getLinkToFile("/1.png"),
          projectDescription:
            languageConfig.bricks[getCubeIndex(1)].walls[getWallIndex(1)]
              .projectDescription,
          projectTechnology:
            languageConfig.bricks[getCubeIndex(1)].walls[getWallIndex(1)]
              .projectTechnology,
          functionToTriger: "qqqq",
        },
        {
          //  graphics - ?
          wallId: 5,
          backgroundImg: getLinkToFile("/g1.png"),
          projectDescription:
            languageConfig.bricks[getCubeIndex(1)].walls[getWallIndex(1)]
              .projectDescription,
          projectTechnology:
            languageConfig.bricks[getCubeIndex(1)].walls[getWallIndex(1)]
              .projectTechnology,
          functionToTriger: "",
        },
        {
          // contact - bottom
          wallId: 6,
          backgroundImg: getLinkToFile("/1.png"),
          projectDescription:
            languageConfig.bricks[getCubeIndex(1)].walls[getWallIndex(1)]
              .projectDescription,
          projectTechnology:
            languageConfig.bricks[getCubeIndex(1)].walls[getWallIndex(1)]
              .projectTechnology,
          functionToTriger: "",
        },
      ],
    },
    {
      cubeId: 2,
      type: "withAnimation",
      //type: 'goToPage',
      linkToPage: "https://designerlink.net",
      walls: [
        {
          // zdjęcie natury - ma być głos?
          wallId: 1,
          backgroundImg: getLinkToFile("/i2.png"),
          projectDescription:
            languageConfig.bricks[1].walls[0].projectDescription,
          projectTechnology:
            languageConfig.bricks[1].walls[0].projectTechnology,
          functionToTriger: "tam",
        },
        {
          // projects - right
          wallId: 2,
          backgroundImg: getLinkToFile("/w2.png"),
          projectDescription:
            languageConfig.bricks[1].walls[0].projectDescription,
          projectTechnology:
            languageConfig.bricks[1].walls[0].projectTechnology,
          functionToTriger: "2222",
        },
        {
          // photos-?
          wallId: 3,
          backgroundImg: getLinkToFile("/m2.png"),
          projectDescription:
            languageConfig.bricks[1].walls[0].projectDescription,
          projectTechnology:
            languageConfig.bricks[1].walls[0].projectTechnology,
          technicalDescription: "fdfd",
        },
        {
          // front-main
          wallId: 4,
          backgroundImg: getLinkToFile("/2.png"),
          projectDescription:
            languageConfig.bricks[1].walls[0].projectDescription,
          projectTechnology:
            languageConfig.bricks[1].walls[0].projectTechnology,
          functionToTriger: "qqqq",
        },
        {
          //  graphics - ?
          wallId: 5,
          backgroundImg: getLinkToFile("/g2.png"),
          projectDescription:
            languageConfig.bricks[1].walls[0].projectDescription,
          projectTechnology:
            languageConfig.bricks[1].walls[0].projectTechnology,
          functionToTriger: "",
        },
        {
          // contact - bottom
          wallId: 6,
          backgroundImg: getLinkToFile("/2.png"),
          projectDescription:
            languageConfig.bricks[1].walls[0].projectDescription,
          projectTechnology:
            languageConfig.bricks[1].walls[0].projectTechnology,
          functionToTriger: "",
        },
      ],
    },

    {
      cubeId: 3,
      type: "withAnimation",
      //type: 'goToPage',
      linkToPage: "https://designerlink.net",
      walls: [
        {
          // zdjęcie natury - ma być głos?
          wallId: 1,
          backgroundImg: getLinkToFile("/i3.png"),
          projectDescription:
            languageConfig.bricks[1].walls[0].projectDescription,
          projectTechnology:
            languageConfig.bricks[1].walls[0].projectTechnology,
          functionToTriger: "tam",
        },
        {
          // projects - right
          wallId: 2,
          backgroundImg: getLinkToFile("/w3.png"),
          projectDescription:
            languageConfig.bricks[1].walls[0].projectDescription,
          projectTechnology:
            languageConfig.bricks[1].walls[0].projectTechnology,
          functionToTriger: "2222",
        },
        {
          // photos-?
          wallId: 3,
          backgroundImg: getLinkToFile("/m3.png"),
          projectDescription:
            languageConfig.bricks[1].walls[0].projectDescription,
          projectTechnology:
            languageConfig.bricks[1].walls[0].projectTechnology,
          technicalDescription: "fdfd",
        },
        {
          // front-main
          wallId: 4,
          backgroundImg: getLinkToFile("/blank.png"),
          projectDescription:
            languageConfig.bricks[1].walls[0].projectDescription,
          projectTechnology:
            languageConfig.bricks[1].walls[0].projectTechnology,
          functionToTriger: "qqqq",
        },
        {
          //  graphics - ?
          wallId: 5,
          backgroundImg: getLinkToFile("/g3.png"),
          projectDescription:
            languageConfig.bricks[1].walls[0].projectDescription,
          projectTechnology:
            languageConfig.bricks[1].walls[0].projectTechnology,
          functionToTriger: "",
        },
        {
          // contact - bottom
          wallId: 6,
          backgroundImg: getLinkToFile("/blank.png"),
          projectDescription:
            languageConfig.bricks[1].walls[0].projectDescription,
          projectTechnology:
            languageConfig.bricks[1].walls[0].projectTechnology,
          functionToTriger: "",
        },
      ],
    },

    {
      cubeId: 4,
      type: "withAnimation",
      //type: 'goToPage',
      linkToPage: "https://designerlink.net",
      walls: [
        {
          // zdjęcie natury - ma być głos?
          wallId: 1,
          backgroundImg: getLinkToFile("/i4.png"),
          projectDescription:
            languageConfig.bricks[1].walls[0].projectDescription,
          projectTechnology:
            languageConfig.bricks[1].walls[0].projectTechnology,
          functionToTriger: "tam",
        },
        {
          // projects - right
          wallId: 2,
          backgroundImg: getLinkToFile("/w4.png"),
          projectDescription:
            languageConfig.bricks[1].walls[0].projectDescription,
          projectTechnology:
            languageConfig.bricks[1].walls[0].projectTechnology,
          functionToTriger: "2222",
        },
        {
          // photos-?
          wallId: 3,
          backgroundImg: getLinkToFile("/m4.png"),
          projectDescription:
            languageConfig.bricks[1].walls[0].projectDescription,
          projectTechnology:
            languageConfig.bricks[1].walls[0].projectTechnology,
          technicalDescription: "fdfd",
        },
        {
          // front-main
          wallId: 4,
          backgroundImg: getLinkToFile("/3.png"),
          projectDescription:
            languageConfig.bricks[1].walls[0].projectDescription,
          projectTechnology:
            languageConfig.bricks[1].walls[0].projectTechnology,
          functionToTriger: "qqqq",
        },
        {
          //  graphics - ?
          wallId: 5,
          backgroundImg: getLinkToFile("/g4.png"),
          projectDescription:
            languageConfig.bricks[1].walls[0].projectDescription,
          projectTechnology:
            languageConfig.bricks[1].walls[0].projectTechnology,
          functionToTriger: "",
        },
        {
          // contact - bottom
          wallId: 6,
          backgroundImg: getLinkToFile("/3.png"),
          projectDescription:
            languageConfig.bricks[1].walls[0].projectDescription,
          projectTechnology:
            languageConfig.bricks[1].walls[0].projectTechnology,
          functionToTriger: "",
        },
      ],
    },

    {
      cubeId: 5,
      type: "withAnimation",
      //type: 'goToPage',
      linkToPage: "https://designerlink.net",
      walls: [
        {
          // zdjęcie natury - ma być głos?
          wallId: 1,
          backgroundImg: getLinkToFile("/i5.png"),
          projectDescription:
            languageConfig.bricks[1].walls[0].projectDescription,
          projectTechnology:
            languageConfig.bricks[1].walls[0].projectTechnology,
          functionToTriger: "tam",
        },
        {
          // projects - right
          wallId: 2,
          backgroundImg: getLinkToFile("/w5.png"),
          projectDescription:
            languageConfig.bricks[1].walls[0].projectDescription,
          projectTechnology:
            languageConfig.bricks[1].walls[0].projectTechnology,
          functionToTriger: "2222",
        },
        {
          // photos-?
          wallId: 3,
          backgroundImg: getLinkToFile("/m5.png"),
          projectDescription:
            languageConfig.bricks[1].walls[0].projectDescription,
          projectTechnology:
            languageConfig.bricks[1].walls[0].projectTechnology,
          technicalDescription: "fdfd",
        },
        {
          // front-main
          wallId: 4,
          backgroundImg: getLinkToFile("/6.png"),
          projectDescription:
            languageConfig.bricks[1].walls[0].projectDescription,
          projectTechnology:
            languageConfig.bricks[1].walls[0].projectTechnology,
          functionToTriger: "qqqq",
        },
        {
          //  graphics - ?
          wallId: 5,
          backgroundImg: getLinkToFile("/g5.png"),
          projectDescription:
            languageConfig.bricks[1].walls[0].projectDescription,
          projectTechnology:
            languageConfig.bricks[1].walls[0].projectTechnology,
          functionToTriger: "",
        },
        {
          // contact - bottom
          wallId: 6,
          backgroundImg: getLinkToFile("/6.png"),
          projectDescription:
            languageConfig.bricks[1].walls[0].projectDescription,
          projectTechnology:
            languageConfig.bricks[1].walls[0].projectTechnology,
          functionToTriger: "",
        },
      ],
    },
    {
      cubeId: 6,
      type: "withAnimation",
      //type: 'goToPage',
      linkToPage: "https://designerlink.net",
      walls: [
        {
          // zdjęcie natury - ma być głos?
          wallId: 1,
          backgroundImg: getLinkToFile("/i6.png"),
          projectDescription:
            languageConfig.bricks[1].walls[0].projectDescription,
          projectTechnology:
            languageConfig.bricks[1].walls[0].projectTechnology,
          functionToTriger: "tam",
        },
        {
          // projects - right
          wallId: 2,
          backgroundImg: getLinkToFile("/w6.png"),
          projectDescription:
            languageConfig.bricks[1].walls[0].projectDescription,
          projectTechnology:
            languageConfig.bricks[1].walls[0].projectTechnology,
          functionToTriger: "2222",
        },
        {
          // photos-?
          wallId: 3,
          backgroundImg: getLinkToFile("/m6.png"),
          projectDescription:
            languageConfig.bricks[1].walls[0].projectDescription,
          projectTechnology:
            languageConfig.bricks[1].walls[0].projectTechnology,
          technicalDescription: "fdfd",
        },
        {
          // front-main
          wallId: 4,
          backgroundImg: getLinkToFile("/blank.png"),
          projectDescription:
            languageConfig.bricks[1].walls[0].projectDescription,
          projectTechnology:
            languageConfig.bricks[1].walls[0].projectTechnology,
          functionToTriger: "qqqq",
        },
        {
          //  graphics - ?
          wallId: 5,
          backgroundImg: getLinkToFile("/g6.png"),
          projectDescription:
            languageConfig.bricks[1].walls[0].projectDescription,
          projectTechnology:
            languageConfig.bricks[1].walls[0].projectTechnology,
          functionToTriger: "",
        },
        {
          // contact - bottom
          wallId: 6,
          backgroundImg: getLinkToFile("/blank.png"),
          projectDescription:
            languageConfig.bricks[1].walls[0].projectDescription,
          projectTechnology:
            languageConfig.bricks[1].walls[0].projectTechnology,
          functionToTriger: "",
        },
      ],
    },
    {
      cubeId: 7,
      type: "withAnimation",
      //type: 'goToPage',
      linkToPage: "https://designerlink.net",
      walls: [
        {
          // zdjęcie natury - ma być głos?
          wallId: 1,
          backgroundImg: getLinkToFile("/i7.png"),
          projectDescription:
            languageConfig.bricks[1].walls[0].projectDescription,
          projectTechnology:
            languageConfig.bricks[1].walls[0].projectTechnology,
          functionToTriger: "tam",
        },
        {
          // projects - right
          wallId: 2,
          backgroundImg: getLinkToFile("/w7.png"),
          projectDescription:
            languageConfig.bricks[1].walls[0].projectDescription,
          projectTechnology:
            languageConfig.bricks[1].walls[0].projectTechnology,
          functionToTriger: "2222",
        },
        {
          // photos-?
          wallId: 3,
          backgroundImg: getLinkToFile("/m7.png"),
          projectDescription:
            languageConfig.bricks[1].walls[0].projectDescription,
          projectTechnology:
            languageConfig.bricks[1].walls[0].projectTechnology,
          technicalDescription: "fdfd",
        },
        {
          // front-main
          wallId: 4,
          backgroundImg: getLinkToFile("/4.png"),
          projectDescription:
            languageConfig.bricks[1].walls[0].projectDescription,
          projectTechnology:
            languageConfig.bricks[1].walls[0].projectTechnology,
          functionToTriger: "qqqq",
        },
        {
          //  graphics - ?
          wallId: 5,
          backgroundImg: getLinkToFile("/g7.png"),
          projectDescription:
            languageConfig.bricks[1].walls[0].projectDescription,
          projectTechnology:
            languageConfig.bricks[1].walls[0].projectTechnology,
          functionToTriger: "",
        },
        {
          // contact - bottom
          wallId: 6,
          backgroundImg: getLinkToFile("/4.png"),
          projectDescription:
            languageConfig.bricks[1].walls[0].projectDescription,
          projectTechnology:
            languageConfig.bricks[1].walls[0].projectTechnology,
          functionToTriger: "",
        },
      ],
    },
    {
      cubeId: 8,
      type: "withAnimation",
      //type: 'goToPage',
      linkToPage: "https://designerlink.net",
      walls: [
        {
          // zdjęcie natury - ma być głos?
          wallId: 1,
          backgroundImg: getLinkToFile("/i8.png"),
          projectDescription:
            languageConfig.bricks[1].walls[0].projectDescription,
          projectTechnology:
            languageConfig.bricks[1].walls[0].projectTechnology,
          functionToTriger: "tam",
        },
        {
          // projects - right
          wallId: 2,
          backgroundImg: getLinkToFile("/w8.png"),
          projectDescription:
            languageConfig.bricks[1].walls[0].projectDescription,
          projectTechnology:
            languageConfig.bricks[1].walls[0].projectTechnology,
          functionToTriger: "2222",
        },
        {
          // photos-?
          wallId: 3,
          backgroundImg: getLinkToFile("/m8.png"),
          projectDescription:
            languageConfig.bricks[1].walls[0].projectDescription,
          projectTechnology:
            languageConfig.bricks[1].walls[0].projectTechnology,
          technicalDescription: "fdfd",
        },
        {
          // front-main
          wallId: 4,
          backgroundImg: getLinkToFile("/7.png"),
          projectDescription:
            languageConfig.bricks[1].walls[0].projectDescription,
          projectTechnology:
            languageConfig.bricks[1].walls[0].projectTechnology,
          functionToTriger: "qqqq",
        },
        {
          //  graphics - ?
          wallId: 5,
          backgroundImg: getLinkToFile("/g8.png"),
          projectDescription:
            languageConfig.bricks[1].walls[0].projectDescription,
          projectTechnology:
            languageConfig.bricks[1].walls[0].projectTechnology,
          functionToTriger: "",
        },
        {
          // contact - bottom
          wallId: 6,
          backgroundImg: getLinkToFile("/7.png"),
          projectDescription:
            languageConfig.bricks[1].walls[0].projectDescription,
          projectTechnology:
            languageConfig.bricks[1].walls[0].projectTechnology,
          functionToTriger: "",
        },
      ],
    },
    {
      cubeId: 9,
      type: "withAnimation",
      //type: 'goToPage',
      linkToPage: "https://designerlink.net",
      walls: [
        {
          // zdjęcie natury - ma być głos?
          wallId: 1,
          backgroundImg: getLinkToFile("/i9.png"),
          projectDescription:
            languageConfig.bricks[1].walls[0].projectDescription,
          projectTechnology:
            languageConfig.bricks[1].walls[0].projectTechnology,
          functionToTriger: "tam",
        },
        {
          // projects - right
          wallId: 2,
          backgroundImg: getLinkToFile("/w9.png"),
          projectDescription:
            languageConfig.bricks[1].walls[0].projectDescription,
          projectTechnology:
            languageConfig.bricks[1].walls[0].projectTechnology,
          functionToTriger: "2222",
        },
        {
          // photos-?
          wallId: 3,
          backgroundImg: getLinkToFile("/m9.png"),
          projectDescription:
            languageConfig.bricks[1].walls[0].projectDescription,
          projectTechnology:
            languageConfig.bricks[1].walls[0].projectTechnology,
          technicalDescription: "fdfd",
        },
        {
          // front-main
          wallId: 4,
          backgroundImg: getLinkToFile("/blank.png"),
          projectDescription:
            languageConfig.bricks[1].walls[0].projectDescription,
          projectTechnology:
            languageConfig.bricks[1].walls[0].projectTechnology,
          functionToTriger: "qqqq",
        },
        {
          //  graphics - ?
          wallId: 5,
          backgroundImg: getLinkToFile("/g9.png"),
          projectDescription:
            languageConfig.bricks[1].walls[0].projectDescription,
          projectTechnology:
            languageConfig.bricks[1].walls[0].projectTechnology,
          functionToTriger: "",
        },
        {
          // contact - bottom
          wallId: 6,
          backgroundImg: getLinkToFile("/blank.png"),
          projectDescription:
            languageConfig.bricks[1].walls[0].projectDescription,
          projectTechnology:
            languageConfig.bricks[1].walls[0].projectTechnology,
          functionToTriger: "",
        },
      ],
    },
    {
      cubeId: 10,
      type: "withAnimation",
      //type: 'goToPage',
      linkToPage: "https://designerlink.net",
      walls: [
        {
          // zdjęcie natury - ma być głos?
          wallId: 1,
          backgroundImg: getLinkToFile("/i10.png"),
          projectDescription:
            languageConfig.bricks[1].walls[0].projectDescription,
          projectTechnology:
            languageConfig.bricks[1].walls[0].projectTechnology,
          functionToTriger: "tam",
        },
        {
          // projects - right
          wallId: 2,
          backgroundImg: getLinkToFile("/w10.png"),
          projectDescription:
            languageConfig.bricks[1].walls[0].projectDescription,
          projectTechnology:
            languageConfig.bricks[1].walls[0].projectTechnology,
          functionToTriger: "2222",
        },
        {
          // photos-?
          wallId: 3,
          backgroundImg: getLinkToFile("/m10.png"),
          projectDescription:
            languageConfig.bricks[1].walls[0].projectDescription,
          projectTechnology:
            languageConfig.bricks[1].walls[0].projectTechnology,
          technicalDescription: "fdfd",
        },
        {
          // front-main
          wallId: 4,
          backgroundImg: getLinkToFile("/5.png"),
          projectDescription:
            languageConfig.bricks[1].walls[0].projectDescription,
          projectTechnology:
            languageConfig.bricks[1].walls[0].projectTechnology,
          functionToTriger: "qqqq",
        },
        {
          //  graphics - ?
          wallId: 5,
          backgroundImg: getLinkToFile("/g10.png"),
          projectDescription:
            languageConfig.bricks[1].walls[0].projectDescription,
          projectTechnology:
            languageConfig.bricks[1].walls[0].projectTechnology,
          functionToTriger: "",
        },
        {
          // contact - bottom
          wallId: 6,
          backgroundImg: getLinkToFile("/5.png"),
          projectDescription:
            languageConfig.bricks[1].walls[0].projectDescription,
          projectTechnology:
            languageConfig.bricks[1].walls[0].projectTechnology,
          functionToTriger: "",
        },
      ],
    },
    {
      cubeId: 11,
      type: "withAnimation",
      //type: 'goToPage',
      linkToPage: "https://designerlink.net",
      walls: [
        {
          // zdjęcie natury - ma być głos?
          wallId: 1,
          backgroundImg: getLinkToFile("/i11.png"),
          projectDescription:
            languageConfig.bricks[1].walls[0].projectDescription,
          projectTechnology:
            languageConfig.bricks[1].walls[0].projectTechnology,
          functionToTriger: "tam",
        },
        {
          // projects - right
          wallId: 2,
          backgroundImg: getLinkToFile("/w11.png"),
          projectDescription:
            languageConfig.bricks[1].walls[0].projectDescription,
          projectTechnology:
            languageConfig.bricks[1].walls[0].projectTechnology,
          functionToTriger: "2222",
        },
        {
          // photos-?
          wallId: 3,
          backgroundImg: getLinkToFile("/m11.png"),
          projectDescription:
            languageConfig.bricks[1].walls[0].projectDescription,
          projectTechnology:
            languageConfig.bricks[1].walls[0].projectTechnology,
          technicalDescription: "fdfd",
        },
        {
          // front-main
          wallId: 4,
          backgroundImg: getLinkToFile("/8.png"),
          projectDescription:
            languageConfig.bricks[1].walls[0].projectDescription,
          projectTechnology:
            languageConfig.bricks[1].walls[0].projectTechnology,
          functionToTriger: "qqqq",
        },
        {
          //  graphics - ?
          wallId: 5,
          backgroundImg: getLinkToFile("/g11.png"),
          projectDescription:
            languageConfig.bricks[1].walls[0].projectDescription,
          projectTechnology:
            languageConfig.bricks[1].walls[0].projectTechnology,
          functionToTriger: "",
        },
        {
          // contact - bottom
          wallId: 6,
          backgroundImg: getLinkToFile("/8.png"),
          projectDescription:
            languageConfig.bricks[1].walls[0].projectDescription,
          projectTechnology:
            languageConfig.bricks[1].walls[0].projectTechnology,
          functionToTriger: "",
        },
      ],
    },
    {
      cubeId: 12,
      type: "withAnimation",
      //type: 'goToPage',
      linkToPage: "https://designerlink.net",
      walls: [
        {
          // zdjęcie natury - ma być głos?
          wallId: 1,
          backgroundImg: getLinkToFile("/i12.png"),
          projectDescription:
            languageConfig.bricks[1].walls[0].projectDescription,
          projectTechnology:
            languageConfig.bricks[1].walls[0].projectTechnology,
          functionToTriger: "tam",
        },
        {
          // projects - right
          wallId: 2,
          backgroundImg: getLinkToFile("/w12.png"),
          projectDescription:
            languageConfig.bricks[1].walls[0].projectDescription,
          projectTechnology:
            languageConfig.bricks[1].walls[0].projectTechnology,
          functionToTriger: "2222",
        },
        {
          // photos-?
          wallId: 3,
          backgroundImg: getLinkToFile("/m12.png"),
          projectDescription:
            languageConfig.bricks[1].walls[0].projectDescription,
          projectTechnology:
            languageConfig.bricks[1].walls[0].projectTechnology,
          technicalDescription: "fdfd",
        },
        {
          // front-main
          wallId: 4,
          backgroundImg: getLinkToFile("/blank.png"),
          projectDescription:
            languageConfig.bricks[1].walls[0].projectDescription,
          projectTechnology:
            languageConfig.bricks[1].walls[0].projectTechnology,
          functionToTriger: "qqqq",
        },
        {
          //  graphics - ?
          wallId: 5,
          backgroundImg: getLinkToFile("/g12.png"),
          projectDescription:
            languageConfig.bricks[1].walls[0].projectDescription,
          projectTechnology:
            languageConfig.bricks[1].walls[0].projectTechnology,
          functionToTriger: "",
        },
        {
          // contact - bottom
          wallId: 6,
          backgroundImg: getLinkToFile("/blank.png"),
          projectDescription:
            languageConfig.bricks[1].walls[0].projectDescription,
          projectTechnology:
            languageConfig.bricks[1].walls[0].projectTechnology,
          functionToTriger: "",
        },
      ],
    },
    {
      cubeId: 13,
      type: "move-4",
      //type: 'goToPage',
      linkToPage: "https://designerlink.net",
      walls: [
        {
          // zdjęcie natury - ma być głos?
          wallId: 1,
          backgroundImg: getLinkToFile("/i13.png"),
          projectDescription:
            languageConfig.bricks[1].walls[0].projectDescription,
          projectTechnology:
            languageConfig.bricks[1].walls[0].projectTechnology,
          functionToTriger: "tam",
        },
        {
          // projects - right
          wallId: 2,
          backgroundImg: getLinkToFile("/photography.png"),
          projectDescription:
            languageConfig.bricks[1].walls[0].projectDescription,
          projectTechnology:
            languageConfig.bricks[1].walls[0].projectTechnology,
          functionToTriger: "2222",
        },
        {
          // photos-?
          wallId: 3,
          backgroundImg: getLinkToFile("/m13.png"),
          projectDescription:
            languageConfig.bricks[1].walls[0].projectDescription,
          projectTechnology:
            languageConfig.bricks[1].walls[0].projectTechnology,
          technicalDescription: "fdfd",
        },
        {
          // front-main
          wallId: 4,
          backgroundImg: getLinkToFile("/9.png"),
          projectDescription:
            languageConfig.bricks[1].walls[0].projectDescription,
          projectTechnology:
            languageConfig.bricks[1].walls[0].projectTechnology,
          functionToTriger: "qqqq",
        },
        {
          //  graphics - ?
          wallId: 5,
          backgroundImg: getLinkToFile("/g13.png"),
          projectDescription:
            languageConfig.bricks[1].walls[0].projectDescription,
          projectTechnology:
            languageConfig.bricks[1].walls[0].projectTechnology,
          functionToTriger: "",
        },
        {
          // contact - bottom
          wallId: 6,
          backgroundImg: getLinkToFile("/9.png"),
          projectDescription:
            languageConfig.bricks[1].walls[0].projectDescription,
          projectTechnology:
            languageConfig.bricks[1].walls[0].projectTechnology,
          functionToTriger: "",
        },
      ],
    },
    {
      cubeId: 14,
      type: "move-5",
      //type: 'goToPage',
      linkToPage: "https://designerlink.net",
      walls: [
        {
          // zdjęcie natury - ma być głos?
          wallId: 1,
          backgroundImg: getLinkToFile("/i14.png"),
          projectDescription:
            languageConfig.bricks[1].walls[0].projectDescription,
          projectTechnology:
            languageConfig.bricks[1].walls[0].projectTechnology,
          functionToTriger: "tam",
        },
        {
          // projects - right
          wallId: 2,
          backgroundImg: getLinkToFile("/music.png"),
          projectDescription:
            languageConfig.bricks[1].walls[0].projectDescription,
          projectTechnology:
            languageConfig.bricks[1].walls[0].projectTechnology,
          functionToTriger: "2222",
        },
        {
          // photos-?
          wallId: 3,
          backgroundImg: getLinkToFile("/m14.png"),
          projectDescription:
            languageConfig.bricks[1].walls[0].projectDescription,
          projectTechnology:
            languageConfig.bricks[1].walls[0].projectTechnology,
          technicalDescription: "fdfd",
        },
        {
          // front-main
          wallId: 4,
          backgroundImg: getLinkToFile("/10.png"),
          projectDescription:
            languageConfig.bricks[1].walls[0].projectDescription,
          projectTechnology:
            languageConfig.bricks[1].walls[0].projectTechnology,
          functionToTriger: "qqqq",
        },
        {
          //  graphics - ?
          wallId: 5,
          backgroundImg: getLinkToFile("/g14.png"),
          projectDescription:
            languageConfig.bricks[1].walls[0].projectDescription,
          projectTechnology:
            languageConfig.bricks[1].walls[0].projectTechnology,
          functionToTriger: "",
        },
        {
          // contact - bottom
          wallId: 6,
          backgroundImg: getLinkToFile("/10.png"),
          projectDescription:
            languageConfig.bricks[1].walls[0].projectDescription,
          projectTechnology:
            languageConfig.bricks[1].walls[0].projectTechnology,
          functionToTriger: "",
        },
      ],
    },
    {
      cubeId: 15,
      type: "move-6",
      //type: 'goToPage',
      linkToPage: "https://designerlink.net",
      walls: [
        {
          // zdjęcie natury - ma być głos?
          wallId: 1,
          backgroundImg: getLinkToFile("/i15.png"),
          projectDescription:
            languageConfig.bricks[1].walls[0].projectDescription,
          projectTechnology:
            languageConfig.bricks[1].walls[0].projectTechnology,
          functionToTriger: "tam",
        },
        {
          // projects - right
          wallId: 2,
          backgroundImg: getLinkToFile("/graphic.png"),
          projectDescription:
            languageConfig.bricks[1].walls[0].projectDescription,
          projectTechnology:
            languageConfig.bricks[1].walls[0].projectTechnology,
          functionToTriger: "2222",
        },
        {
          // photos-?
          wallId: 3,
          backgroundImg: getLinkToFile("/m15.png"),
          projectDescription:
            languageConfig.bricks[1].walls[0].projectDescription,
          projectTechnology:
            languageConfig.bricks[1].walls[0].projectTechnology,
          technicalDescription: "fdfd",
        },
        {
          // front-main
          wallId: 4,
          backgroundImg: getLinkToFile("/11.png"),
          projectDescription:
            languageConfig.bricks[1].walls[0].projectDescription,
          projectTechnology:
            languageConfig.bricks[1].walls[0].projectTechnology,
          functionToTriger: "qqqq",
        },
        {
          //  graphics - ?
          wallId: 5,
          backgroundImg: getLinkToFile("/g15.png"),
          projectDescription:
            languageConfig.bricks[1].walls[0].projectDescription,
          projectTechnology:
            languageConfig.bricks[1].walls[0].projectTechnology,
          functionToTriger: "",
        },
        {
          // contact - bottom
          wallId: 6,
          backgroundImg: getLinkToFile("/11.png"),
          projectDescription:
            languageConfig.bricks[1].walls[0].projectDescription,
          projectTechnology:
            languageConfig.bricks[1].walls[0].projectTechnology,
          functionToTriger: "",
        },
      ],
    },
  ];
};
