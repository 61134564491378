import "./style.css";
import ContentData from "./content.js";
import React, { useState, useEffect, useContext, createContext } from "react";
import { addEventListenerForKeydownOnWindow } from "./App.helpers";
// import { getLanguageConfig } from "./helpers/global.helpers";
import { getLinkToFile } from "./helpers/global.helpers.js";
import PictShow from "./components/pict-show/pict-show.jsx";
import { LanguageContext } from "./config/language-context";
import Bricks from "./components/bricks/brick-frame";

const flagConfigurationArray = [
  { name: "f1", value: getLinkToFile("/dflag.png") },
  { name: "f2", value: getLinkToFile("/eflag.png") },
  { name: "f3", value: getLinkToFile("/jflag.png") },
];

export const Frame = ({ setLanguage }) => {
  const [style, setStyle] = useState("");
  let [repx, setRepx] = useState(true);
  let [contact, setContact] = useState(false);
  const [flagConfig, setFlagConfig] = useState(flagConfigurationArray[0]);
  const [showImg, setShowImg] = useState(false);
  const [itemToShow, setItemToShow] = useState("");
  // const [itemToHide, setItemToHide] = useState(true);

  const language = useContext(LanguageContext);

  const changeFlag = () =>
    setFlagConfig(
      flagConfig.name === "f1"
        ? flagConfigurationArray[1]
        : flagConfig.name === "f2"
        ? flagConfigurationArray[2]
        : flagConfigurationArray[0]
    );

  useEffect(() => {
    addEventListenerForKeydownOnWindow(
      repx,
      move,
      setRepx,
      setContact,
      setLanguage,
      PictShow
    );
  }, []);

  const move = (action) => {
    action === "1"
      ? setStyle("enter")
      : action === "2"
      ? setStyle("enter2")
      : action === "3"
      ? setStyle("enter3")
      : action === "4"
      ? setStyle("enter4")
      : action === "5"
      ? setStyle("enter5")
      : setStyle("enter6");
  };

  const Title = (props) => {
    const [title, setTitle] = useState("");
    const titleString = ContentData[language].sectionHeader.title;

    useEffect(() => {
      for (let i = 0; i < titleString.length; i++) {
        setTimeout(() => {
          setTitle((title) => title + titleString[i]);
        }, i * 55);
      }
    }, [language]);
    return <div className="titleStyle">{title}</div>;
  };

  return (
    <div className="frameClass">
      <PictShow
        showImg={showImg}
        item={itemToShow}
        // itemHide={itemToHide}
        // setItemToHide={setItemToHide}
      />
      <div className="frameLeft">
        <Bricks
          animationConfig={{ style, move }}
          configurationForWallBack={{ setItemToShow, setShowImg }}
        />
      </div>
      <div>
        <img
          className={repx ? "haloPictureClass" : "heloNone"}
          src={getLinkToFile("/halo.png")}
          alt="halo message pic"
        />
        <img
          className={contact ? "haloContactClass" : "heloNone"}
          src={getLinkToFile("/HaloContact.png")}
          alt="contact message pic"
        />
      </div>
      <div className="frameRight">
        <div className="Nav">
          <ul className="menu">
            <li
              className="linkTransform"
              onClick={() => {
                move("1");
                setTimeout(() => setRepx(true), 500);
                setContact(false);
              }}
            >
              Home (1)
            </li>
            <li
              className="linkTransform"
              onClick={() => {
                move("2");
                setRepx(false);
                setContact(false);
              }}
            >
              Portfolio (2)
            </li>
            <li
              className="linkTransform"
              onClick={() => {
                move("3");
                setRepx(false);
                setTimeout(() => setContact(true), 500);
              }}
            >
              Contact (3)
            </li>

            <li
              className="linkTransformFlag"
              onClick={() => {
                switch (language) {
                  case "de":
                    setLanguage("en");
                    break;
                  case "en":
                    setLanguage("jp");
                    break;
                  default:
                    setLanguage("de");
                }
                console.log(
                  "flagCongfigArray: ",
                  flagConfigurationArray[1].value
                );
                changeFlag();
                // changeFlag();
              }}
            >
              <img
                className="flag"
                src={flagConfig.value}
                alt="change language to Japanese"
              />
            </li>
          </ul>
        </div>

        <div>
          <p className="name"> {ContentData[language].sectionHeader.header}</p>
          {/* <p className="name"> {ContentData[language].sectionHeader.header}</p> */}
          <p>
            <Title />
          </p>
          {/* <p>{repx}</p> */}
        </div>
        <div>
          <p>{ContentData[language].sectionMain.intro}</p>
          <p className="content">
            {ContentData[language].sectionMain.paragraph_1}
          </p>
          <p className="content">
            {ContentData[language].sectionMain.paragraph_2}
          </p>
          <p className="content">
            {ContentData[language].sectionMain.paragraph_3}
          </p>
          <p className="content">
            {ContentData[language].sectionMain.paragraph_4}
          </p>
        </div>
        <div></div>
      </div>
    </div>
  );
};

// const Language = React.createContext({ texts: ContentData.en });

// const Language = React.createContext({
//   texts: ContentData.en,
//   language: "en",
//   setLanguage: () => {},
// });

// const { text, language, setLanguage } = useContext(Language);

const App = () => {
  const [language, setLanguage] = useState("de");

  return (
    <LanguageContext.Provider value={language}>
      <div className="App">
        <Frame setLanguage={setLanguage} />
      </div>
    </LanguageContext.Provider>
  );
};

// function App() {
//   const currentLanguage = useContext(Language);
//   // const [language, setLanguage] = useState("de");
//   // ^^ zmienic useState na useContext
//   return (
//     <Language.Provider value={ContentData.de}>
//       <div className="App">
//         <Frame />
//       </div>
//     </Language.Provider>
//   );
// }

export default App;
