export const WallTop = ({
  wall,
  configurationForWallBack: { setShowImg, setItemToShow },
}) => {
  return (
    <button className="cube__face cube__face--top ">
      <img
        onClick={() => {
          setShowImg(true);
          setItemToShow(wall.backgroundImg);
        }}
        className="imgStyle"
        src={wall.backgroundImg}
        alt="forest item"
      />
    </button>
  );
};
