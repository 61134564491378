import { useContext, useMemo } from "react";
import { getBrickContent } from "./bricks";

// Components
import { WallBack } from "./walls/wall-back";
import { WallRight } from "./walls/wall-right";
import { WallLeft } from "./walls/wall-left";
import { WallFront } from "./walls/wall-front";
import { WallTop } from "./walls/wall-top";
import { WallBottom } from "./walls/wall-bottom";
import { LanguageContext } from "../../config/language-context";

const Bricks = ({
  configurationForWallBack,
  animationConfig: { style, move },
}) => {
  const language = useContext(LanguageContext);
  const bricks = useMemo(() => {
    return getBrickContent(language);
  }, [language]);

  return (
    <div className="brickScene">
      {bricks.map((cube) => (
        <div className="scene">
          <div className={`cube ${style}`}>
            <WallRight wall={cube.walls[0]} />
            <WallBack
              wall={cube.walls[1]}
              type={cube.type}
              linkToPage={cube.linkToPage}
              move={move}
            />
            <WallLeft wall={cube.walls[2]} linkToPage={cube.linkToPage} />
            <WallFront wall={cube.walls[3]} />
            <WallTop
              wall={cube.walls[4]}
              configurationForWallBack={configurationForWallBack}
            />
            <WallBottom wall={cube.walls[5]} />
          </div>
        </div>
      ))}
    </div>
  );
};

export default Bricks;
