export const contentData = {
  de: {
    sectionHeader: {
      header: "Ich bin Maciek Lubocki",
      title: "js und python developer.",
    },
    sectionMain: {
      intro: "Willkommen auf meiner Website",
      paragraph_1:
        "Ich bin Webentwickler mit Kunst- und Designhintergrund. Ich habe eine Leidenschaft für Computergrafik und liebe das Gespür für gutes Design. Ich lasse mich von der Natur, den Farben, Ansichten und Klängen inspirieren. Einfachheit, Funktionalität und effektive Botschaft an die Zielgruppe sind die Schlüsselfaktoren, die ich bei der Erstellung von Webanwendungen berücksichtige",
      paragraph_2:
        "Ich baue seit dem Jahr 2009 Webseiten. Seitdem habe ich mehrere Projekte gemacht, von denen ich einige noch betreue. Im Jahr 2021 habe ich ein Jahr intensive Programmiertrainings abgeschlossen: FrontEnd Developer plus und Python Developer. Derzeit entwickle ich täglich meine Erlernen von Fähigkeiten in verschiedenen Schulungen und Erstellen von Websites für Kunden. Da Webentwicklung meine wahre Leidenschaft ist, mache ich auch meine eigenen Projekte, um etwas Neues zu präsentieren",
      paragraph_3:
        "In meiner täglichen Arbeit verwende ich jetzt JavaScript mit dem React-Framework. CSS/SASS und HTML. Für Backend Python/Flask und Datenbank SQLite.",
      paragraph_4:
        "Bitte zögern Sie nicht, mich zu kontaktieren, wenn Sie Fragen haben.",
    },
    bricks: [
      {
        cubeId: 1,
        walls: [
          {
            wallId: 2,
            projectDescription: "Qi Medicine",
            projectTechnology: ": benutzte Technologie: React/SASS",
          },
        ],
      },
      {
        cubeId: 2,
        walls: [
          {
            wallId: 2,
            projectDescription: "DesignerLink",
            projectTechnology: ": benutzte Technologie: JS/CSS/Python/SQLite",
          },
        ],
      },
      {
        cubeId: 3,
        walls: [
          {
            wallId: 2,
            projectDescription: "MattNae.com",
            projectTechnology: ": benutzte Technologie: HTML/JS/CSS",
          },
        ],
      },
      {
        cubeId: 4,
        walls: [
          {
            wallId: 2,
            projectDescription: "Scisorrs-Stone-Paper",
            projectTechnology: "technology: JS/CSS/HTML",
          },
        ],
      },
      {
        cubeId: 5,
        walls: [
          {
            wallId: 2,
            projectDescription: "MakuBot.com",
            projectTechnology: "project of interactive webpage",
          },
        ],
      },
      {
        cubeId: 6,
        walls: [
          {
            wallId: 2,
            projectDescription: "OK Architektura",
            projectTechnology: "technology: HTML/CSS",
          },
        ],
      },
      {
        cubeId: 7,
        walls: [
          {
            wallId: 2,
            projectDescription: "Home Website",
            projectTechnology: "technology: JS/HTML/CSS",
          },
        ],
      },
      {
        cubeId: 8,
        walls: [
          {
            wallId: 2,
            projectDescription: "Cut-&-Dry",
            projectTechnology: "technology: JS/HTML/CSS",
          },
        ],
      },
      {
        cubeId: 9,
        walls: [
          {
            wallId: 2,
            projectDescription: "Small Shop Website",
            projectTechnology: "technology: JS/CSS/HTML",
          },
        ],
      },
      {
        cubeId: 10,
        walls: [
          {
            wallId: 2,
            projectDescription: "My Blog",
            projectTechnology: "technology: Vanilla/CSS/HTML",
          },
        ],
      },
      {
        cubeId: 11,
        walls: [
          {
            wallId: 2,
            projectDescription: "Simple Dashboard",
            projectTechnology: "technology: JS/HTML/CSS",
          },
        ],
      },
      {
        cubeId: 12,
        walls: [
          {
            wallId: 2,
            projectDescription: "Photograpy",
            projectTechnology: "click to see my photos",
          },
        ],
      },
      {
        cubeId: 13,
        walls: [
          {
            wallId: 2,
            projectDescription: "Music Compositions",
            projectTechnology: "click to listen to my music",
          },
        ],
      },
      {
        cubeId: 14,
        walls: [
          {
            wallId: 2,
            projectDescription: "Graphics",
            projectTechnology: "click to see my graphics",
          },
        ],
      },
      {
        cubeId: 15,
        walls: [
          {
            wallId: 2,
            projectDescription: "DesignerLink",
            projectTechnology: "technology: Vanilla/CSS/Python",
          },
        ],
      },
    ],
  },
  en: {
    sectionHeader: {
      header: "I am Maciek Lubocki",
      title: "js and python developer.",
    },
    sectionMain: {
      intro: "Welcome on my site",
      paragraph_1:
        "I’m a web developer with an art & design background. I have a passion to computer graphics and love to sense of good design. I am taking the inspiration form the nature, colors, views and sounds. Simplicity, functionality and effective message to target audience is the key factors which I am taking into consideratio while creating web applications",
      paragraph_2:
        "I am building webpages since year 2009. Since then I made several projects which soeme of them I still maintain. In 2021 I finished one year intensive programming trainings: FrontEnd Developer plus, and Python Developer.  Currently on a daily basis I am developing my skills learning on various trainings and creating website for customers. As web development is my real passion I am also making my own projects wanting to present something new.",
      paragraph_3:
        "In my daily work I am using JavaScript now with React framework. CSS/SASS and HTML. For backend Python/Flask and database SQLite.",
      paragraph_4:
        "Please don't hesitate to contact me if you have any questions.",
    },
    bricks: [
      {
        cubeId: 1,
        walls: [
          {
            wallId: 2,
            projectDescription: "Qi Medicine",
            projectTechnology: ": benutzte Technologie: React/SASS",
          },
        ],
      },
      {
        cubeId: 2,
        walls: [
          {
            wallId: 2,
            projectDescription: "DesignerLink",
            projectTechnology: ": benutzte Technologie: JS/CSS/Python/SQLite",
          },
        ],
      },
      {
        cubeId: 3,
        walls: [
          {
            wallId: 2,
            projectDescription: "MattNae.com",
            projectTechnology: ": benutzte Technologie: HTML/JS/CSS",
          },
        ],
      },
      {
        cubeId: 4,
        walls: [
          {
            wallId: 2,
            projectDescription: "Scisorrs-Stone-Paper",
            projectTechnology: "technology: JS/CSS/HTML",
          },
        ],
      },
      {
        cubeId: 5,
        walls: [
          {
            wallId: 2,
            projectDescription: "MakuBot.com",
            projectTechnology: "project of interactive webpage",
          },
        ],
      },
      {
        cubeId: 6,
        walls: [
          {
            wallId: 2,
            projectDescription: "OK Architektura",
            projectTechnology: "technology: HTML/CSS",
          },
        ],
      },
      {
        cubeId: 7,
        walls: [
          {
            wallId: 2,
            projectDescription: "Home Website",
            projectTechnology: "technology: JS/HTML/CSS",
          },
        ],
      },
      {
        cubeId: 8,
        walls: [
          {
            wallId: 2,
            projectDescription: "Cut-&-Dry",
            projectTechnology: "technology: JS/HTML/CSS",
          },
        ],
      },
      {
        cubeId: 9,
        walls: [
          {
            wallId: 2,
            projectDescription: "Small Shop Website",
            projectTechnology: "technology: JS/CSS/HTML",
          },
        ],
      },
      {
        cubeId: 10,
        walls: [
          {
            wallId: 2,
            projectDescription: "My Blog",
            projectTechnology: "technology: Vanilla/CSS/HTML",
          },
        ],
      },
      {
        cubeId: 11,
        walls: [
          {
            wallId: 2,
            projectDescription: "Simple Dashboard",
            projectTechnology: "technology: JS/HTML/CSS",
          },
        ],
      },
      {
        cubeId: 12,
        walls: [
          {
            wallId: 2,
            projectDescription: "Photograpy",
            projectTechnology: "click to see my photos",
          },
        ],
      },
      {
        cubeId: 13,
        walls: [
          {
            wallId: 2,
            projectDescription: "Music Compositions",
            projectTechnology: "click to listen to my music",
          },
        ],
      },
      {
        cubeId: 14,
        walls: [
          {
            wallId: 2,
            projectDescription: "Graphics",
            projectTechnology: "click to see my graphics",
          },
        ],
      },
      {
        cubeId: 15,
        walls: [
          {
            wallId: 2,
            projectDescription: "DesignerLink",
            projectTechnology: "technology: Vanilla/CSS/Python",
          },
        ],
      },
    ],
  },

  jp: {
    sectionHeader: {
      header: "単田和大吉",
      title: "js and python developer.",
    },
    sectionMain: {
      intro: "ようこそ",
      paragraph_1:
        "私はアートとデザインのバックグラウンドを持つWeb開発者です。私はコンピュータグラフィックスに情熱を持っており、優れたデザインの感覚が大好きです。自然、色、景色、音からインスピレーションを得ています。シンプルさ、機能性、ターゲットオーディエンスへの効果的なメッセージは、Webアプリケーションを作成する際に考慮している重要な要素です。",
      paragraph_2:
        "私は2009年からウェブページを作成しています。それ以来、私は今でも維持しているいくつかのプロジェクトを作成しました。 2021年に、私は1年間の集中的なプログラミングトレーニングを終了しました。FrontEndDeveloperplusとPythonDeveloperです。現在、日々、さまざまなトレーニングでスキルを磨き、お客様向けのウェブサイトを作成しています。ウェブ開発は私の本当の情熱なので、私はまた、何か新しいものを提示したい自分のプロジェクトを作っています。",
      paragraph_3:
        "私の日常業務では、現在ReactフレームワークでJavaScriptを使用しています。 CSS / SASSおよびHTML。バックエンドPython / FlaskおよびデータベースSQLiteの場合。",
      paragraph_4: "ご不明な点がございましたら、お気軽にお問い合わせください。",
    },
    bricks: [
      {
        cubeId: 1,
        walls: [
          {
            wallId: 2,
            projectDescription: "Qi Medicine",
            projectTechnology: ": benutzte Technologie: React/SASS",
          },
        ],
      },
      {
        cubeId: 2,
        walls: [
          {
            wallId: 2,
            projectDescription: "DesignerLink",
            projectTechnology: ": benutzte Technologie: JS/CSS/Python/SQLite",
          },
        ],
      },
      {
        cubeId: 3,
        walls: [
          {
            wallId: 2,
            projectDescription: "MattNae.com",
            projectTechnology: ": benutzte Technologie: HTML/JS/CSS",
          },
        ],
      },
      {
        cubeId: 4,
        walls: [
          {
            wallId: 2,
            projectDescription: "Scisorrs-Stone-Paper",
            projectTechnology: "technology: JS/CSS/HTML",
          },
        ],
      },
      {
        cubeId: 5,
        walls: [
          {
            wallId: 2,
            projectDescription: "MakuBot.com",
            projectTechnology: "project of interactive webpage",
          },
        ],
      },
      {
        cubeId: 6,
        walls: [
          {
            wallId: 2,
            projectDescription: "OK Architektura",
            projectTechnology: "technology: HTML/CSS",
          },
        ],
      },
      {
        cubeId: 7,
        walls: [
          {
            wallId: 2,
            projectDescription: "Home Website",
            projectTechnology: "technology: JS/HTML/CSS",
          },
        ],
      },
      {
        cubeId: 8,
        walls: [
          {
            wallId: 2,
            projectDescription: "Cut-&-Dry",
            projectTechnology: "technology: JS/HTML/CSS",
          },
        ],
      },
      {
        cubeId: 9,
        walls: [
          {
            wallId: 2,
            projectDescription: "Small Shop Website",
            projectTechnology: "technology: JS/CSS/HTML",
          },
        ],
      },
      {
        cubeId: 10,
        walls: [
          {
            wallId: 2,
            projectDescription: "My Blog",
            projectTechnology: "technology: Vanilla/CSS/HTML",
          },
        ],
      },
      {
        cubeId: 11,
        walls: [
          {
            wallId: 2,
            projectDescription: "Simple Dashboard",
            projectTechnology: "technology: JS/HTML/CSS",
          },
        ],
      },
      {
        cubeId: 12,
        walls: [
          {
            wallId: 2,
            projectDescription: "Photograpy",
            projectTechnology: "click to see my photos",
          },
        ],
      },
      {
        cubeId: 13,
        walls: [
          {
            wallId: 2,
            projectDescription: "Music Compositions",
            projectTechnology: "click to listen to my music",
          },
        ],
      },
      {
        cubeId: 14,
        walls: [
          {
            wallId: 2,
            projectDescription: "Graphics",
            projectTechnology: "click to see my graphics",
          },
        ],
      },
      {
        cubeId: 15,
        walls: [
          {
            wallId: 2,
            projectDescription: "DesignerLink",
            projectTechnology: "technology: Vanilla/CSS/Python",
          },
        ],
      },
    ],
  },
};

export default contentData;
