import { contentData } from "../content";

export const getLinkToFile = (link) => process.env.PUBLIC_URL + link;
export const getLanguageConfig = (language = "en") => contentData[language];

// getLanguageConfig()
// getLanguageConfig('de')

export const getCubeIndex = (cubeId) => cubeId - 1;
export const getWallIndex = (wallId) => wallId - 1;
