export const WallBack = ({ wall, type, linkToPage, move }) => {
  return (
    <div className="cube__face photoC cube__face--back">
      {type === "withAnimation" ? (
        <a href={linkToPage} target="_blank">
          <div className="overlayC">
            <h1>{wall.projectDescription}</h1>
            <p>{wall.projectTechnology}</p>
          </div>
          {/* console.log('cube.type: ', cube.type) */}
          <div className="overlayBar"></div>
          <div className="overlayBar2"></div>
          <img
            className="imgStyle"
            src={wall.backgroundImg}
            alt="portfolio item"
          />
        </a>
      ) : (
        <button
          onClick={() => {
            move(type === "move-4" ? "4" : type === "move-5" ? "5" : "6");
          }}
        >
          <div className="overlayC">
            <h1>{wall.projectDescription}</h1>
            <p>{wall.projectTechnology}</p>
          </div>
          {/* console.log('cube.type: ', cube.type) */}
          <div className="overlayBar"></div>
          <div className="overlayBar2"></div>
          <img
            className="imgStyle"
            src={wall.backgroundImg}
            alt="portfolio item"
          />
        </button>
      )}
    </div>
  );
};
