//  props = {
//      moveFunction,
//      data
//  }

// cos, cos2, cos3, cos4, w funnkcjach argumenty
// props = {valuemoveFuc, data} -> w komponentach props

export const addEventListenerForKeydownOnWindow = (
  repx,
  move,
  setRepx,
  setContact
) => {
  window.addEventListener("keydown", (e) => {
    switch (e.key) {
      case "1":
        move("1");
        setTimeout(() => setRepx(repx), 500);
        setContact(false);
        break;
      case "2":
        move("2");
        setRepx(false);
        setContact(false);
        break;
      case "3":
        move("3");
        setRepx(false);
        setTimeout(() => setContact(true), 500);
        break;
      case "4":
        move("4");
        setRepx(false);
        setContact(false);
        break;
      case "5":
        move("5");
        setRepx(false);
        setContact(false);
        break;
      case "6":
        move("6");
        setRepx(false);
        setContact(false);
        break;
      default:
        alert("Want more please ask?");
        setRepx(false);
        setContact(false);
        break;
    }
  });
};
