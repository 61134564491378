export const WallBottom = ({ wall }) => {
  return (
    <button className="cube__face cube__face--bottom ">
      <img
        className="imgStyle"
        src={wall.backgroundImg}
        alt="contact face background"
      />
    </button>
  );
};
